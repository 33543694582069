<template>
    <input type="number"
           class="qty-input"
           :class="customClass ? customClass : 'w-100'"
           v-model="localValue"
           :placeholder="field.placeholder"
           :name="field.slug"
           :data-vv-as="field.title"
           v-validate="validations">
</template>

<script>
export default {
    inject: ['$validator'],
    props: {
        field: {
            type: Object,
            required: true
        },
        value: {
            required: true
        },
        validations: {
            type: String,
            default: 'required'
        },
        customClass: String
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                if (newValue <= 0) {
                    this.localValue = 1;
                } else if (newValue > 999) {
                    this.localValue = 999;
                }
            },
            immediate: true
        }
    },
}
</script>

<style>
.qty-input {
    padding: 2px 6px;
    font-size: 14px;
    max-width: 55px;
    min-width: 45px;
}
</style>